<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required">
            <div class="authorization">
                <h3>Авторизация</h3><br>
                <v-text-field
                    :error-messages="errors[0]"
                    label="Телефон"
                    type="tel"
                    autocomplete="off"
                    :value="phone"
                    outlined
                    @input="onPhoneInput"
                    @change="onPhoneInput"
                    @keyup.enter="login"
                />
                <v-btn
                    color="primary"
                    :disabled="loading"
                    @click="login">
                    Авторизоваться
                    <template v-if="loading">
                        &nbsp;
                        <v-progress-circular
                            :size="20"
                            color="white"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                </v-btn>
            </div>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        name: 'Authorization',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            phone: '+7 ',
            loading: false
        }),
        methods: {
            onPhoneInput(value) {
                value = value.replace(/[^\d]/g, '');
                if(value[0] === '7') {
                    value = value.slice(1);
                } 

                if (value.length > 8) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d{3})(\d{2})(\d*)/, '$1 $2 $3 $4');
                } else if (value.length > 6) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d{3})(\d*)/, '$1 $2 $3');
                } else if (value.length > 3) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d*)/, '$1 $2');
                } else {
                    this.phone = '+7 ' + value.replace(/(\d*)/, '$1');
                }
            },
            async login() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    this.loading = true;
                    await store.dispatch('auth/login', { phone: this.phone });
                    await this.$router.push({ name: 'user-confirmation' });
                    this.loading = false;
                }
            },
            async registration() {
                await this.$router.push({ name: 'user-registration' });
            }
        }
    }
</script>

<style lang="scss">
.authorization {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: 50%;
    margin: 0 0 0 -200px;
    padding: 0 20px;
}
</style>
